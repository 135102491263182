import React, { Component }  from 'react'
import moment from 'moment'
import axios from 'axios'
import styled from 'styled-components'
import Board from './boards/Board'
import SortableTable from './tables/SortableTable.js'
import MonitoringRequestFilters from './filters/MonitoringRequestFilters'
import IpPlanForm from './forms/IpPlanForm.js'
import { appConstants, setIpPlan } from 'utils/constants.js'
import I18n from 'i18n-js/locales.js'
import { CSVLink } from 'react-csv'
import { Tabs, Tab, Modal, Button, Collapse } from 'react-bootstrap'
import { BsBoxArrowDown, BsBoxArrowUp } from 'react-icons/bs'



const NewPlanButton = styled(Button)`
  right: 1.5em;
  position: absolute;
  z-index: 0;
  `;

const FiltersButton = styled.div`
  left: .5em;
  top: .1em;
  position: absolute;
  z-index: 0;
  cursor: pointer;
  color: #205492;
  `;

const ExportWrapper = styled(CSVLink)`
  left: 88%;
  top: -6px;
  position: relative;
  z-index: 0;
  `;

export default class IpPlanningBoard extends Component {

  state = {
    initialData: this.props.initialData.data || [],
    filteredData: this.props.initialData.data || [],
    filters: this.props.initialData.filters,
    tableColumns: this.props.initialData.columns,
    planningBoard: this.props.initialData.planning_board,
    reportingBoard: this.props.initialData.reporting_board,
    viewModal: false,
    selectedPlanId: "",
    selectedTab: "planning_board",
    showFilters: true,
  }

  componentDidMount() {
    const planningBoard = this.setBoardData(this.state.planningBoard, this.state.initialData)
    const reportingBoard = this.setBoardData(this.state.reportingBoard, this.state.initialData)
    const newState = {
      ...this.state,
      planningBoard: planningBoard,
      reportingBoard: reportingBoard,
    }
    this.setState(newState)
  }

  refreshData = () => {
    axios.get(appConstants.IP_PLANNING_URL)
    .then(res => {
      const ipPlans = res.data;
      this.setState({
        initialData: ipPlans.data,
      }, () => {
        this.filterData(this.state.filters)
      })
    })
    .catch(error => {
      console.log(error);
    })
  }

  // filtering functions
  handleFilterChange = (e, filterBy, clickClear) => {
    const filters = this.state.filters
    let filterValue = null
    if (e && (this.state.filters[filterBy] != e.value || !clickClear)) { filterValue = e.value }
    const newFilters = {
      ...filters,
      [filterBy]: filterValue,
    }
    this.filterData(newFilters)
  }

  filterData = (filters) => {
    const data = this.state.initialData
    const filterKeys = Object.keys(filters)
    let filteredData = [...data]
    for (let filterKey of filterKeys) {
      if (filters[filterKey]) {
        if (filters[filterKey] == -1) {
          filteredData = filteredData.filter(task => (task[filterKey] == "" || task[filterKey] == null) )
        } else {
          filteredData = filteredData.filter(task => task[filterKey] == filters[filterKey] )
        }
      }
    }

    const planningBoard = this.setBoardData(this.state.planningBoard, filteredData)
    const reportingBoard = this.setBoardData(this.state.reportingBoard, filteredData)
    const newState = {
      ...this.state,
      filteredData: filteredData,
      planningBoard: planningBoard,
      reportingBoard: reportingBoard,
      filters: filters,
    }
    this.setState(newState)
  }

  //  ip board functions
  setBoardData = (board, data) => {
    const columnKeys = Object.keys(board.columns)
    let newColumns = board.columns
    let newTasks = {}
    for (let key of columnKeys) {
      newColumns[key]["taskIds"] = []
    }
    data.map(task => {
      if (newColumns[task.ip_plan_status]) {
        task.id = task.id.toString()
        newTasks[task.id] = task
        newColumns[task.ip_plan_status]["taskIds"].push(task.id)
      }
    })
 
    const newBoard = {
      ...board,
      tasks: newTasks,
      columns: newColumns,
    }
    return newBoard
  }

  dropDisabled = (index, columnId, homeIndex, multidirectional) => {
    if (multidirectional && this.props.allowed.edit_plan) {
      return false
    } else if (!this.props.allowed.edit_plan) {
      return true
    } else {
      return index < homeIndex
    }
  }

  taskContent = (task) => {
    return (
      <div>
        <strong><a href='#' onClick={() => {this.editPlan(task)}}>{task.id}. {task.activity_name}</a></strong> <br/>
        {I18n.t('activerecord.attributes.ip_plan.office')}: {task.office_name} <br/>
        {I18n.t('activerecord.attributes.ip_plan.user')}: {task.assignee_name} <br/>
        {I18n.t('activerecord.attributes.ip_plan.sites_requested')}: {task.sites_requested_date ? moment(task.sites_requested_date).format('M-D-YYYY') : ""} <br/>
        {I18n.t('activerecord.attributes.ip_plan.sites_provided')}: {task.sites_provided_date ? moment(task.sites_provided_date).format('M-D-YYYY') : ""} <br/>
        {I18n.t('activerecord.attributes.ip_plan.sites_proposed')}: {task.sites_proposed_date ? moment(task.sites_proposed_date).format('M-D-YYYY') : ""} <br/>
        {I18n.t('activerecord.attributes.ip_plan.initial_meeting')}: {task.initial_meeting_date ? moment(task.initial_meeting_date).format('M-D-YYYY') : ""} <br/>
      </div>
    )
  }

  planModalLink = (cell, row) => {
    return (
      cell["column"]["id"] === "activity_name"
        ? <a href='#' onClick={() => {this.editPlan(row.original)}}>{row["original"]["activity_name"]}</a>
        : cell.render('Cell') 
    )
  }

  persistStatus(id, status) {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    axios.put(`${appConstants.IP_PLAN_URL}${id}`, {"ip_plan_status": status} )
    .then(res => {
      this.refreshData()
    })
    .catch(error => {
      console.log(error)
    })
  }

  onPlanningDragStart = start => {
    this.onDragStart(start,"planningBoard")
  }

  onReportingDragStart = start => {
    this.onDragStart(start,"reportingBoard")
  }

  onPlanningDragEnd = result => {
    this.onDragEnd(result,"planningBoard")
  }

  onReportingDragEnd = result => {
    this.onDragEnd(result,"reportingBoard")
  }

  onDragStart = (start, board) => {
    const homeIndex = this.state[board].columnOrder.indexOf(start.source.droppableId);
  
    const draggingState = {
      ...this.state[board],
      homeIndex: homeIndex
    }
    this.setState({[board]: draggingState})
  }

  onDragEnd = (result, board) => {
    const droppedState = {
      ...this.state[board],
      homeIndex: null
    }
    this.setState({[board]: droppedState})

    const { destination, source, draggableId } = result;

    // Moving aborted
    if(!destination) {
      return;
    }

    //Moving to original spot
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = this.state[board].columns[source.droppableId]
    const finish = this.state[board].columns[destination.droppableId]

    // Moving within same column 
    if(start === finish) {
      const newTaskIds = Array.from(start.taskIds)
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };
    
      const newState = {
        ...this.state[board],
        columns: {
          ...this.state[board].columns,
          [newColumn.id]: newColumn,
        },
      };
    
      this.setState({[board]: newState})
      return
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...this.state[board],
      columns: {
        ...this.state[board].columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    this.setState({[board]: newState})
    this.persistStatus(draggableId, destination.droppableId)
  }

  // modal/form functions
  editPlan = (plan) => {
    this.setState({
      selectedPlanId: plan.id,
      viewModal: true
    })
  }

  newPlan = () => {
    this.setState({
      viewModal: true
    })
  }

  closeModal = () => {
    this.setState({
      selectedPlanId: "",
      viewModal: false
    })
  }

  renderIpPlanModalContent() {
    return (
      <IpPlanForm col={6}
        allowed={this.props.allowed}
        ipPlanId={this.state.selectedPlanId}
        authenticityToken={this.props.authenticityToken}
        refreshData={this.refreshData}
        closeModal={this.closeModal} />
    )
  }

  renderPlanModal() {
    return (
      <Modal
        show={this.state.viewModal}
        onHide={this.closeModal}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>{`${I18n.t('activerecord.attributes.ip_plan.ip_plan')} ${this.state.selectedPlanId ? this.state.selectedPlanId : ""}` }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderIpPlanModalContent()}
        </Modal.Body>
      </Modal>
    )
  }

  renderNewPlanButton() {
    return (
      <NewPlanButton variant="outline-primary"  onClick={() => this.newPlan()}>{I18n.t('ip_plan.new')}</NewPlanButton>
    )
  }

  renderFiltersButton() {
    return (
      <FiltersButton 
        onClick={() => this.setState({showFilters: !this.state.showFilters})} 
        aria-controls="collapse-filters" 
        aria-expanded={this.state.showFilters}>
          <h4>{this.state.showFilters ? <BsBoxArrowUp /> : <BsBoxArrowDown />}</h4>
      </FiltersButton>
    ) 
  }

  renderPlanningBoard() {
    return (this.state.selectedTab == "planning_board") ?
      (
        <Board 
          onDragStart={this.onPlanningDragStart}
          onDragEnd={this.onPlanningDragEnd}
          dropDisabled={this.dropDisabled}
          taskContent={this.taskContent}
          initialData={this.state.planningBoard}
        />
      ) : ""
  }

  renderReportingBoard() {
    return (this.state.selectedTab == "reporting_board") ?
      (
        <Board 
          onDragStart={this.onReportingDragStart}
          onDragEnd={this.onReportingDragEnd}
          dropDisabled={this.dropDisabled}
          taskContent={this.taskContent}
          initialData={this.state.reportingBoard}
        />
      ) : ""
  }

  renderList() {
    return (this.state.selectedTab == "list") ?
      (
        <SortableTable
          data={this.state.filteredData}
          columns={this.state.tableColumns}
          useRenderFunction={true}
          cellContent={this.planModalLink}
        />
      ) : ""
  }

  render() {
    return (
      <>
        {this.renderPlanModal()}
        <div>
          <Collapse in={this.state.showFilters}>
            <div id="collapse-filters">
              <MonitoringRequestFilters handleFilterChange={this.handleFilterChange} includedFilters={this.state.filters} excludeSearch={true} />
            </div>
          </Collapse>
          {this.renderFiltersButton()}
        </div>
        <div>
          {this.renderNewPlanButton()}
          <Tabs defaultActiveKey={this.state.selectedTab} id="listTabs" onSelect={(selectedKey) => this.setState({selectedTab: selectedKey})}>
            <Tab eventKey="planning_board" title={I18n.t("headers.ip_plan.planning_board")}>
              <br/>
              {this.renderPlanningBoard()}
            </Tab>
            <Tab eventKey="reporting_board" title={I18n.t("headers.ip_plan.reporting_board")}>
              <br/>
              {this.renderReportingBoard()}
            </Tab>
            <Tab eventKey="list" title={I18n.t("headers.ip_plan.list")}>
              <br/>
              <ExportWrapper data={this.state.filteredData}>{I18n.t("headers.ip_plan.export")}</ExportWrapper>
              {this.renderList()}
            </Tab>
          </Tabs>
        </div>
      </>
    )
  }
}

