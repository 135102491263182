import React from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { formatValidationErrors } from 'utils/format.js'
import { appConstants } from 'utils/constants.js'
import SelectSingle from '../selects/SelectSingle.js'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

export default class ZoneForm extends React.Component {
  static defaultProps = {
    zone: {
      id: "",
      title: "",
      pCode: "",
    },
    isModal: false,
    closeModal: () => {},
  }
  
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.zone.id || "",
      title: this.props.zone.name || "",
      territoryId: this.props.zone.territory_id || "",
      pCode: this.props.zone.pcode || "",
      alertMessage: "",
    }
  }

  handleClose = (e) => {
    this.props.closeModal(e);
  }

  handleZoneSubmit = (e) => {
    e.preventDefault();
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;

    let formData = new FormData()
    formData.append('zone[name]', this.state.title)
    formData.append('zone[territory_id]', this.state.territoryId)
    formData.append('zone[pcode]', this.state.pCode)

    if (this.state.id) {
      axios.put(`${appConstants.ZONES_URL}${this.state.id}`, formData)
      .then(res => {
        return window.location.href = appConstants.ZONES_URL
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('zone', error.response.data) });
      })
    } else {
      axios.post(`${appConstants.ZONES_URL}`, formData)
      .then(res => {
        this.handleClose(res.data.id);
        if (!this.props.isModal) {
          return window.location.href = appConstants.ZONES_URL
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('zone', error.response.data) });
      })
    }
  }

  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    });
  }

  handleAlertMessage = () => {
    if (this.state.alertMessage){
      return (
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          {this.state.alertMessage}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )
    } else {
      return ""
    }
  }

  render() {
    return (
      <Form>
        <h5>{I18n.t('zone.add')}</h5><hr/>
        <Row>
          <Col>
            {this.handleAlertMessage()}
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col} md={12} className="mb-3">
            <Form.Label>{I18n.t('activerecord.attributes.zone.name')}<span className="text-danger">*</span></Form.Label>
            <Form.Control
              name="title"
              type="text"
              value={this.state.name}
              onChange={this.handleInputChange} />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md={6} className="mb-3">
            <Form.Label>{I18n.t('activerecord.attributes.zone.territory')}</Form.Label>
            <SelectSingle
              selected={this.state.territoryId}
              onValueChange={this.handleTerritoryChange}
              url={appConstants.TERRITORIES_URL}
              placeholder={I18n.t('selects.single_optional')}
              isClearable={!this.state.isModal}
              isDisabled={!this.state.isModal} />
          </Form.Group>
          <Form.Group as={Col} md={6} className="mb-3">
            <Form.Label>{I18n.t('activerecord.attributes.zone.pcode')}</Form.Label>
            <Form.Control
              name="pCode"
              type="text"
              value={this.state.pCode}
              onChange={this.handleInputChange} />
          </Form.Group>
        </Row>

        <Form.Group>
          <Button variant="outline-primary" onClick={this.handleZoneSubmit}>{this.state.id ? I18n.t('navigation.update') : I18n.t('navigation.add')}</Button> &nbsp;
          <Button variant="outline-secondary" href={this.props.isModal ? "#" : appConstants.ZONES_URL} onClick={this.handleClose}>{I18n.t('navigation.cancel')}</Button>
        </Form.Group>
      </Form>
    )
  }



  


}