import React from "react"
import { Form, Col, Row } from 'react-bootstrap'
import Select from 'react-select'

export default function SelectMonthYear(props) {
  const now = new Date().getUTCFullYear()
  const yearOpts = Array((now + 1) - (now - 10)).fill('').map((v, idx) => (now + 1) - idx);
  const years = yearOpts.map(y => ({value: y, label: y, field: "year"}))
  const months = [
    {value: 0, label: "January"},
    {value: 1, label: "February"},
    {value: 2, label: "March"},
    {value: 3, label: "April"},
    {value: 4, label: "May"},
    {value: 5, label: "June"},
    {value: 6, label: "July"},
    {value: 7, label: "August"},
    {value: 8, label: "September"},
    {value: 9, label: "October"},
    {value: 10, label: "November"},
    {value: 11, label: "December"}
  ]

  return (
    <Row>
      <Form.Group as={Col} controlId="form.month">
        <Select
          isDisabled={props.isDisabled}
          clearValue={true}
          isClearable={true}
          onChange={props.onChange}
          value={months.find(obj=>obj.value == props.month)}
          options={months}
          placeholder=""
          name="myMonth"
          styles={{
            menu: provided => ({ ...provided, zIndex: 9999 })
          }} />
      </Form.Group>

      <Form.Group as={Col} controlId="form.year">
        <Select
          isDisabled={props.isDisabled}
          clearValue={true}
          isClearable={true}
          onChange={props.onChange}
          value={years.find(obj=>obj.value == props.year)}
          options={years}
          placeholder=""
          name="myYear"
          styles={{
            menu: provided => ({ ...provided, zIndex: 9999 })
          }} />
      </Form.Group>
    </Row>
  )
}