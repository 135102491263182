import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { Form, Col, Button, Stack, Card, Badge, Alert } from 'react-bootstrap'
import { appConstants, localTimezone, userTypeBadge } from 'utils/constants.js'
import { formatValidationErrors } from 'utils/format.js'
import { BsTrashFill } from 'react-icons/bs'

export default function Comments(props) {
  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState('')
  const [alertMessage, setAlertMessage] = useState('')

  useEffect(() => {
    if (props.objectId) {
      const fetchData = async () => {
        const response = await axios.get(`${appConstants.OBJECT_COMMENTS_URL}.json?${props.objectType}_id=${props.objectId}&comment_type=${props.commentType}&sort=${props.sort}`)
        setComments(response.data)
      }
      fetchData()
    }
  }, [])

  const createFormData = () => {
    let formData = new FormData()
    formData.append('comment[text]', newComment)
    formData.append('comment[comment_type]', props.commentType)
    formData.append(`comment[${props.objectType}_id]`, props.objectId)
    return formData
  }

  const handleSubmit = async () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const data = createFormData()
    await axios.post(`${appConstants.COMMENTS_URL}`, data)
    .then(res => {
      let newComments = comments
      newComments.push(res.data)
      setComments(newComments.sort((a,b) => { return props.sort == 'asc' ? new Date(a.updated_at) - new Date(b.updated_at) : new Date(b.updated_at) - new Date(a.updated_at) }))
      setNewComment('')
    })
    .catch(err => {
      console.log(err)
      setAlertMessage(formatValidationErrors('comment', err.response.data))
    })
  }

  const handleDelete = async (c) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    if (confirm(I18n.t('warnings.are_you_sure_delete_comment'))) {
      await axios.delete(`${appConstants.COMMENTS_URL}/${c.id}.json`)
      .then(() => {
        setComments(comments.filter(x => x!=c))
      })
      .catch(err => {
        console.log(err)
        setAlertMessage(I18n.t("notices.delete_failure"))
      })
    }
  }

  const handleAlertMessage = () => {
    if (alertMessage){
      return (
        <Alert variant={'info'} onClose={() => setAlertMessage(null)} dismissible>
          {alertMessage}
        </Alert>
      )
    } else {
      return ""
    }
  };

  const renderDeleteButton = (c) => {
    return c.editable && props.open ? (
      <Button variant="link" onClick={() => handleDelete(c)} className='text-warning float-end p-0'><BsTrashFill /> </Button>
    ) : ""
  }

  const renderComment = (c) => {
    const badge = userTypeBadge(c.user_type)

    return (
      <Card key={c.id}>
        <Card.Header>{c.user_name}, {moment(c.updated_at).tz(localTimezone()).format('MMMM Do YYYY, h:mm:ss a')}&nbsp; <Badge bg={badge['color']}>{badge['text']}</Badge>{renderDeleteButton(c)}</Card.Header>
        <Card.Body>
          {c.text}
        </Card.Body>
      </Card>
    );
  }

  const renderForm = () => {
    return props.open ? (
      <Stack direction="horizontal" gap={3}>
        <Form.Control
          name="newComment"
          type="text"
          value={newComment}
          placeholder={I18n.t('navigation.enter_comments')}
          aria-label={I18n.t('navigation.enter_comments')}
          onChange={(c) => setNewComment(c.target.value)} />
        <Button onClick={handleSubmit}>{I18n.t('navigation.add')}</Button>
      </Stack>
    ) : ""
  }
  
  return (
    <Stack gap={3} as={Col}>
      {handleAlertMessage()}
      {props.sort == 'desc' ? renderForm() : ""}
      {comments.map(c => renderComment(c))}
      {props.sort == 'asc' ? renderForm() : ""}
    </Stack>
  );
}