import React, { Component }  from 'react'
import axios from 'axios'
import { appConstants } from 'utils/constants.js'
import styled from 'styled-components'
import SortableTable from './tables/SortableTable.js'
import { CSVLink } from 'react-csv'
import { Button } from 'react-bootstrap'
import Fuse from 'fuse.js'
import AdminFilters from './filters/AdminFilters'

const NewItemButton = styled(Button)`
  right: 1.5em;
  position: absolute;
  z-index: 0;
  `;

const ExportWrapper = styled(CSVLink)`
  left: 88%;
  top: -6px;
  position: relative;
  z-index: 0;
  `;

export default class AdminList extends Component {

  state = {
    initialData: this.props.initialData.data || [],
    filteredData: this.props.initialData.data || [],
    filters: this.props.initialData.filters || [],
    tableColumns: this.props.initialData.columns,
    searchTerms: "",
  }

  refreshData = () => {
    axios.get(appConstants.QUESTIONS_URL)
    .then(res => {
      const questions = res.data;
      this.setState({
        initialData: questions.data,
      }, () => {
        this.filterData(this.state.filters)
      })
    })
    .catch(error => {
      console.log(error);
    })
  }

  // filtering functions
  handleFilterChange = (e, filterBy, clickClear) => {
    const filters = this.state.filters
    let filterValue = null
    if (e && (this.state.filters[filterBy] != e.value || !clickClear)) { filterValue = e.value }
    const newFilters = {
      ...filters,
      [filterBy]: filterValue,
    }
    this.filterData(newFilters)
  }

  handleSearch = (e) => {
    this.setState({
      searchTerms: e.target.value
    }, () => {
        this.filterData(this.state.filters)
    })
  }

  filterData = (filters) => {
    const searchOptions = {
      // https://fusejs.io/api/options.html for full list of options
      minMatchCharLength: 3,
      threshold: 0.1,
      distance: 300,
      keys: this.props.searchKeys
    }
    const data = this.state.initialData
    const filterKeys = Object.keys(filters)
    let filteredData = [...data]
    for (let filterKey of filterKeys) {
      if (filters[filterKey]) {
        if (filters[filterKey] == -1) {
          filteredData = filteredData.filter(task => (task[filterKey] == "" || task[filterKey] == null) )
        } else {
          filteredData = filteredData.filter(task => task[filterKey] == filters[filterKey] )
        }
        
      }
    }
    if (this.state.searchTerms.length > 2) {
      const fuse = new Fuse(filteredData, searchOptions);
      const searchResults = fuse.search(this.state.searchTerms);
      filteredData = searchResults.map(task => task.item)
    }
    const newState = {
      ...this.state,
      filteredData: filteredData,
      filters: filters,
    }
    this.setState(newState)
  }

  editLink = (cell, row) => {
    return (
      cell["column"]["id"] === "name"
        ? <a href={this.props.editPath + "/" + row["original"]["id"] + "/edit"} >{row["original"]["name"]}</a>
        : cell["column"]["id"] === "email"
        ? <a href={this.props.editPath + "/" + row["original"]["id"] + "/edit"} >{row["original"]["email"]}</a>
        : cell.render('Cell')
    )
  }

  renderList() {
    return (
      <>
        {this.props.exportFilename && <ExportWrapper data={this.state.filteredData} filename={this.props.exportFilename + ".csv" } headers={this.props.exportHeaders}>Export Filtered Records</ExportWrapper>}
        <SortableTable
          data={this.state.filteredData}
          columns={this.state.tableColumns}
          useRenderFunction={true}
          cellContent={this.editLink}
        />
      </>
    ) 
  }

  render() {
    return (
      <>
        <div>
            <AdminFilters handleSearch={this.handleSearch}  />
        </div>

        <div>
          {this.renderList()}
        </div>
      </>
    )
  }

}