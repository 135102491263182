import React, { useState, useEffect } from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import moment from 'moment'
import SelectSingle from '../selects/SelectSingle.js'
import SelectMonthYear from '../selects/SelectMonthYear.js'
import SelectMultiple from '../selects/SelectMultiple.js'
import CollectionForm from './CollectionForm.js'
import { formatValidationErrors } from 'utils/format.js'
import { appConstants, isUnacceptableFileType, currentFiscalYearStartDate } from 'utils/constants.js'
import { uploadFile } from 'utils/directUpload.js'
// to use local storage for files, comment out line above, and uncomment the line below
// import { uploadFile } from 'utils/localUpload.js'
import { Form, Row, Col, Button, InputGroup, Modal, Alert, Spinner } from 'react-bootstrap'
import { BiReset, BiPlus } from "react-icons/bi";

export default function ResourceForm(props) {
  const [id, setId] = useState(props.id || '')
  const [title, setTitle] = useState('')
  const [monitoringRequestId, setMonitoringRequestId] = useState(props.resource ? props.resource['monitoring_request_id'] : '')
  const [officeId, setOfficeId] = useState('')
  const [tagIds, setTagIds] = useState([])
  const [collectionIds, setCollectionIds] = useState([])
  const [activityIds, setActivityIds] = useState((props.resource && props.resource['activities']) ? props.resource['activities'].map(i => i.id) : [])
  const [ipPlanIds, setIpPlanIds] = useState([])
  const [lessonIds, setLessonIds] = useState((props.resource && props.resource['lessons']) ? props.resource['lessons'].map(i => i.id) : [])
  const [description, setDescription] = useState('')
  const [resourceTypeId, setResourceTypeId] = useState(props.resource ? props.resource['resource_type_id'] : '')
  const [monitoringCycleId, setMonitoringCycleId] = useState('')
  const [fyYear, setFyYear] = useState(moment(currentFiscalYearStartDate()).year()) 
  const [fyMonth, setFyMonth] = useState(moment(currentFiscalYearStartDate()).month())
  const [isPublic, setIsPublic] = useState(false) 
  const [isInternal, setIsInternal] = useState(false) 
  const [file, setFile] = useState(null) 
  const [url, setUrl] = useState('')
  const [blobIdOrFile, setBlobIdOrFile] = useState('')
  const [fileName, setFileName] = useState(props.filename || '')

  const [alertMessage, setAlertMessage] = useState('')
  const [invalidURL, setInvalidURL] = useState(false)
  const [showAddCollectionModal, setShowAddCollectionModal] = useState(false)
  const [firstUpdate, setFirstUpdate] = useState(true)
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const loadResource = async () => {
      const response = await axios.get(`${appConstants.RESOURCES_URL}${id}.json`)
      const resource = response.data
      setTitle(resource.name)
      setMonitoringRequestId(resource.monitoring_request_id || '')
      setOfficeId(resource.office_id || '')
      setTagIds(resource.tags ? resource.tags.map(i => i.id) : [])
      setCollectionIds(resource.collections ? resource.collections.map(i => i.id) : [])
      setActivityIds(resource.activities ? resource.activities.map(i => i.id) : [])
      setIpPlanIds(resource.ip_plans ? resource.ip_plans.map(i => i.id) : [])
      setLessonIds(resource.flags ? resource.flags.map(i => i.id) : [])
      setDescription(resource.description || '')
      setResourceTypeId(resource.resource_type_id)
      setMonitoringCycleId(resource.monitoring_cycle_id || '')
      if (resource.fiscal_year) {
        setFyYear(moment(resource.fiscal_year).year())
        setFyMonth(moment(resource.fiscal_year).month())
      }
      setIsPublic(resource.is_public || false)
      setIsInternal(resource.is_internal || false)
      setFile(resource.file)
      setUrl(resource.url)
      setBlobIdOrFile(resource.signed_id || '')
      setFileName(resource.filename || '')
    }

    if (props.id) {loadResource()}
  }, [props.id])

  useEffect(() => {
    updateMonitoringRequestAttributes(monitoringRequestId)
  }, [props.monitoring_request_id])

  // uploads the file only if it does not already exist, and appends it to request data accordingly
  const createResourceData = async () => {
    let formData = new FormData()
    formData.append('resource[name]', title)
    formData.append('resource[monitoring_request_id]', monitoringRequestId || "")
    formData.append('resource[office_id]', officeId || "")
    formData.append('resource[monitoring_cycle_id]', monitoringCycleId || "")

    tagIds.length > 0
      ? tagIds.forEach(tagId => {
        formData.append('resource[tag_ids][]', tagId)
      })
      : formData.set('resource[tag_ids][]', "")

    if (resourceTypeId) { formData.append('resource[tag_ids][]', resourceTypeId) }

    collectionIds.length > 0
      ? collectionIds.forEach(collectionId => {
        formData.append('resource[collection_ids][]', collectionId)
      })
      : formData.set('resource[collection_ids][]', "")

    activityIds.length > 0
      ? activityIds.forEach(activityId => {
        if (activityId) {formData.append('resource[activity_ids][]', activityId)}
      })
      : formData.set('resource[activity_ids][]', "")

    ipPlanIds.length > 0
      ? ipPlanIds.forEach(ippId => {
        formData.append('resource[ip_plan_ids][]', ippId)
      })
      : formData.set('resource[ip_plan_ids][]', "")

    lessonIds.length > 0
      ? lessonIds.forEach(lessonId => {
        formData.append('resource[flag_ids][]', lessonId)
      })
      : formData.set('resource[flag_ids][]', "")

    formData.append('resource[url]', url)
    formData.append('resource[description]', description)
    formData.append('resource[fiscal_year]', fiscalYearDate())
    formData.append('resource[is_public]', isPublic)
    formData.append('resource[is_internal]', isInternal)

    if (!url && file) {
      if (isUnacceptableFileType(file.name)) {
        throw I18n.t('errors.unacceptable_file_type')
      }

      if (!blobIdOrFile) {
        const blob = await uploadFile(file)
        formData.append('resource[file]',  blob)
        setBlobIdOrFile(blob)
      } else {
        formData.append('resource[file]',  blobIdOrFile)
      }
    }
    setLoading(false);
    return formData
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    if (id) {
      createResourceData().then((resource) => {
        axios.put(`${appConstants.RESOURCES_URL}${id}`, resource)
        .then(res => {
          props.closeModal ? props.closeModal() : window.location.href = appConstants.SEARCH_URL
        })
        .catch(error => {
          console.log(error)
          setAlertMessage(formatValidationErrors('resource', error.response.data))
        })
      })
      .catch(error => {
        setAlertMessage(error)
      })
    } else {
      createResourceData().then((resource) => {
        axios.post(`${appConstants.RESOURCES_URL}`,  resource )
        .then(res => {
          props.closeModal ? props.closeModal() : window.location.href = appConstants.SEARCH_URL
        })
        .catch(error => {
          console.log(error);
          setAlertMessage(formatValidationErrors('resource', error.response.data))
        })
      })
      .catch(error => {
        setAlertMessage(error)
      })
    }
  }

  const updateMonitoringRequestAttributes = (id) => {
    if (id) {
      setMonitoringRequestId(id)
      const loadMonitoringRequest = async () => {
        const response = await axios.get(`${appConstants.MONITORING_EVENT_URL}${id}.json`)
        setOfficeId(response.data.office_id)
        setMonitoringCycleId(response.data.monitoring_cycle_id)
        let a = new Array
        a.push(response.data.activity_id)
        setActivityIds(a)
      }
      loadMonitoringRequest()
    }
  }

  const renderAlertMessage = () => {
    if (alertMessage){
      return (
        <Alert variant={'info'} onClose={() => setAlertMessage(null) } dismissible>
          {alertMessage}
        </Alert>
      )
    } else {
      return ""
    }
  }

  const handleURLBlur = (e) => {
    if (!validURL(e.target.value) && file == "") {
      setAlertMessage("Please enter a valid file URL or upload a file")
    } else if (!validURL(e.target.value) && file) {
      setUrl("")
    }
  }

  const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str)
  }
  
  const handleFYDateChange = (e) => {
    e.field=="year" ? setFyYear(e.value) : setFyMonth(e.value)
  }

  const fiscalYearDate = () => {
    let d = moment();
    d.set({'year': fyYear, 'month': fyMonth})
    return d
  }

  const closeAddCollectionModal = (c) => {
    let selected = collectionIds.slice()
    if (c) { selected.push(c) }
    setShowAddCollectionModal(false)
    setCollectionIds(selected)
  }

  return (
    <Form>
      <Modal
        show={showAddCollectionModal}
        onHide={closeAddCollectionModal}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('collection.add_a_collection')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CollectionForm
            authenticityToken={props.authenticityToken}
            isModal={true}
            closeModal={closeAddCollectionModal} />
        </Modal.Body>
      </Modal>

      <Row>
        <Col>
          {renderAlertMessage()}
        </Col>
      </Row>

      <Row>
        <Form.Group as={Col} md={8} className="mb-3" controlId="resourceForm.name">
          <Form.Label>{I18n.t('activerecord.attributes.resource.name')}<span className="text-danger">*</span></Form.Label>
          <Form.Control
            name="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            disabled={loading} />
        </Form.Group>

        <Form.Group as={Col} md={4} className="mb-3" controlId="resourceForm.tags">
          <Form.Label>{I18n.t('activerecord.attributes.tag_type.resource_type')}<span className="text-danger">*</span></Form.Label>
          <SelectSingle
            selected={resourceTypeId}
            onValueChange={(e) => setResourceTypeId(e ? e.value : "")}
            url={appConstants.RESOURCE_TYPE_TAGS_URL}
            placeholder={I18n.t('selects.single')}
            disabled={loading} />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} className="mb-3" controlId="resourceForm.file">
          <Form.Label>{fileName ? fileName : I18n.t('resource.upload_file')}</Form.Label>
          <InputGroup>
            <Form.Control 
              type="file" 
              disabled={url || loading}
              onChange={(e) => {
                setBlobIdOrFile("")
                setUrl("")
                setFile(e.target.files[0])
                setFileName(e.target.files[0].name)
              }}
              placeholder={fileName || I18n.t('resource.upload_file')} />
            <Button variant="outline-secondary" onClick={() => {
              setBlobIdOrFile("")
              setFile(null)
              setFileName("")
            }}><BiReset /></Button>
          </InputGroup>
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} className="mb-3" controlId="resourceForm.url">
          <Form.Label>{I18n.t('resource.file_url')}<span className="text-danger"></span></Form.Label>
          <Form.Control 
            name="url"
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            onBlur={handleURLBlur}
            disabled={fileName || loading} />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} md={3} controlId="resourceForm.is_public">
          <Form.Label>{I18n.t('activerecord.attributes.resource.public')}</Form.Label>
          <Form.Check
            type="switch"
            name="isPublic"
            checked={isPublic}
            value={true}
            onChange={() => {
              setIsPublic(!isPublic)
              setIsInternal(false)
            }}
            bsPrefix="isPublic-checkbox"
            disabled={loading} />
        </Form.Group>

        <Form.Group as={Col} md={3} controlId="resourceForm.is_internal">
          <Form.Label>{I18n.t('activerecord.attributes.resource.is_internal')}</Form.Label>
          <Form.Check
            type="switch"
            name="isInternal"
            checked={isInternal}
            value={true}
            onChange={() => {
              setIsInternal(!isInternal)
              setIsPublic(false)
            }}
            bsPrefix="isPublic-checkbox"
            disabled={loading} />
        </Form.Group>

        <Form.Group as={Col} md={6} className="mb-3" controlId="resourceForm.monitoring_requests">
          <Form.Label>{I18n.t('activerecord.attributes.resource.monitoring_request')}</Form.Label>
          <SelectSingle
            selected={monitoringRequestId}
            onValueChange={(e) => updateMonitoringRequestAttributes(e ? e.value : "")}
            url={appConstants.REQUESTS_FOR_RESOURCES_URL}
            placeholder={I18n.t('selects.single_optional')}
            isClearable={true}
            disabled={loading} />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} md={6} className="mb-3" controlId="resourceForm.office">
          <Form.Label>{I18n.t('activerecord.attributes.resource.office')}</Form.Label>
          <SelectSingle
            isDisabled={monitoringRequestId}
            selected={officeId}
            onValueChange={(e) => setOfficeId(e ? e.value : "")}
            url={appConstants.OFFICES_URL}
            placeholder={I18n.t('selects.single_optional')}
            isClearable={true}
            disabled={loading} />
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="resourceForm.activity">
          <Form.Label>{I18n.t('activerecord.attributes.resource.activities')}</Form.Label>
          <SelectMultiple
            isDisabled={monitoringRequestId}
            selected={activityIds}
            onChange={(e) => setActivityIds(e ? e.map(i => i.value) : [])}
            url={appConstants.ACTIVITIES_URL + ".json"}
            placeholder={I18n.t('selects.multiple_optional')}
            disabled={loading} />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} md={6} className="mb-3" controlId="resourceForm.collections">
          <Form.Label>{I18n.t('activerecord.attributes.resource.collections')} &nbsp; </Form.Label>
          <Button variant="outline-secondary" onClick={() => setShowAddCollectionModal(true)} className="add-collection-btn"><BiPlus /></Button>
          <SelectMultiple
            selected={collectionIds}
            onChange={(e) => setCollectionIds(e ? e.map(i => i.value) : [])}
            url={appConstants.COLLECTIONS_JSON_URL}
            placeholder={I18n.t('selects.multiple_optional')}
            disabled={loading} />
        </Form.Group>

        <Form.Group as={Col} md={6} className="mb-3" controlId="resourceForm.lessons">
          <Form.Label>{I18n.t('activerecord.attributes.resource.flags')} &nbsp; </Form.Label>
          <SelectMultiple
            selected={lessonIds}
            onChange={(e) => setLessonIds(e ? e.map(i => i.value) : [])}
            url={appConstants.LESSONS_LIST_URL}
            placeholder={I18n.t('selects.multiple_optional')}
            disabled={loading} />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} md={6} className="mb-3" controlId="resourceForm.monitoring_cycle">
          <Form.Label>{I18n.t('activerecord.attributes.resource.monitoring_cycle')}</Form.Label>
          <SelectSingle
            isDisabled={monitoringRequestId}
            selected={monitoringCycleId}
            onValueChange={(e) => setMonitoringCycleId(e ? e.value : "")}
            url={appConstants.MONITORING_CYCLES_URL}
            placeholder={I18n.t('selects.single_optional')}
            isClearable={true}
            disabled={loading} />
        </Form.Group>

        <Form.Group as={Col} md={6} className="mb-3" controlId="resourceForm.fy">
          <Form.Label>{I18n.t('activerecord.attributes.resource.fiscal_year')}</Form.Label>
          <SelectMonthYear
            month={fyMonth}
            year={fyYear}
            onChange={(e) => handleFYDateChange(e)}
            disabled={loading} />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} className="mb-3" controlId="resourceForm.description">
          <Form.Label>{I18n.t('activerecord.attributes.resource.description')}</Form.Label>
          <Form.Control 
            as="textarea" 
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows="2"
            disabled={loading} />
        </Form.Group>
      </Row>

      <Form.Group  className="mb-3" controlId="resourceForm.buttons">
        <Button variant="outline-primary" disabled={loading} onClick={handleSubmit}>{loading ? <Spinner animation="border" size="sm"/> : id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
        <Button variant="outline-secondary" onClick={props.closeModal ? props.closeModal : ()=>{}} href={props.closeModal ? "#" : appConstants.SEARCH_URL}>{I18n.t("navigation.cancel")}</Button>
      </Form.Group>
    </Form>
  )
  }
