import React, { useEffect, useState }  from "react"
import axios from 'axios'
import Select from 'react-select'

export default function SelectGroupedMultiple(props) {
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState([])

  const loadOptions = async () => {
    const res = await axios.get(props.url)
    const groupedOptions = Object.keys(res.data).map(k => ({label: k, options: res.data[k][props.groupedName].map(t => ({label: t.name, value: t.id}))}))
    const selectedOptions = props.selected.map(i => (groupedOptions.find(obj => obj.options.find(o => o.value === i)).options.find(o => o.value === i)))
    setOptions(groupedOptions)
    setSelected(selectedOptions)
  }

  useEffect(() => {
    loadOptions()
  }, [props.selected])

  return (  
    <Select 
      isMulti={true}
      closeMenuOnSelect={false}
      isSearchable={true}
      value={selected} 
      onChange={props.onChange} 
      options={options}
      isDisabled={props.isDisabled}
      placeholder={props.placeholder} />
  )
}