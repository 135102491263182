import React, { useState, useEffect }  from 'react'
import axios from 'axios'
import styled from 'styled-components'
import I18n from 'i18n-js/locales.js'
import SortableTable from './tables/SortableTable.js'
import MonitoringRequestFilters from './filters/MonitoringRequestFilters.js'
import LessonForm from './forms/LessonForm.js'
import Modal from 'react-bootstrap/Modal'
import Collapse from 'react-bootstrap/Collapse'
import { CSVLink } from 'react-csv'
import { BsBoxArrowDown, BsBoxArrowUp } from 'react-icons/bs'
import { appConstants, setLesson } from 'utils/constants.js'

const FiltersButton = styled.div`
  left: .5em;
  top: .1em;
  position: absolute;
  z-index: 0;
  cursor: pointer;
  color: #205492;
  `;

const ExportWrapper = styled(CSVLink)`
  left: 88%;
  top: -6px;
  position: relative;
  z-index: 0;
  `;


export default function TPMLessonsArchive(props) {
  const [initialData, setInitialData] = useState(props.initialData.data || [])
  const [filteredData, setFilteredData] = useState(props.initialData.data || [])
  const [filters, setFilters] = useState(props.initialData.filters)
  const [tableColumns, setTableColumns] = useState(props.initialData.columns)

  const [viewModal, setViewModal] = useState(false)
  const [selectedLesson, setSelectedLesson] = useState({})
  const [selectedTab, setSelectedTab] = useState('list')
  const [showFilters, setShowFilters] = useState(true)

  useEffect(() => {
    filterData(filters)
  }, [initialData])

  const refreshData = () => {
    axios.get(`${appConstants.FLAGS_URL}.json`)
    .then(res => {
      const lessons = res.data;
      setInitialData(lessons.data)
    })
    .catch(error => {
      console.log(error);
    })
  }

  // filtering functions
  const handleFilterChange = (e, filterBy, clickClear) => {
    let filterValue = null
    if (e && (filters[filterBy] != e.value || !clickClear)) { filterValue = e.value }
    const newFilters = {
      ...filters,
      [filterBy]: filterValue,
    }
    filterData(newFilters)
  }

  const filterData = (filters) => {
    const data = initialData
    const filterKeys = Object.keys(filters)
    let filteredData = [...data]
    for (let filterKey of filterKeys) {
      if (filters[filterKey]) {
        if (filters[filterKey] == -1) {
          filteredData = filteredData.filter(task => (task[filterKey] == "" || task[filterKey] == null) )
        } else {
          filteredData = filteredData.filter(task => task[filterKey] == filters[filterKey] )
        }
      }
    }
    setFilteredData(filteredData)
    setFilters(filters)
  }

  const cellContent = (cell, row) => {
    return (
      cell["column"]["id"] === "name"
        ? (props.allowed.edit_lesson ? <a href='#' onClick={() => {editLesson(row.original)}}>{row["original"]["name"]}</a> : row["original"]["name"])
        : cell.render('Cell')
    )
  }

  // modal/form functions
  const editLesson = (f) => {
    const lesson = setLesson(f)
    setSelectedLesson(lesson)
    setViewModal(true)
  }

  const closeModal = () => {
    setViewModal(false)
  }

  const renderLessonModalContent = () => {
    return (
      <LessonForm col={6}
        id={selectedLesson.id}
        allowed={props.allowed}
        authenticityToken={props.authenticityToken}
        refreshData={refreshData}
        closeModal={closeModal} />
    )
  }

  const renderLessonModal = () => {
    return props.allowed.edit_lesson ?
    (
      <Modal
        show={viewModal}
        onHide={closeModal}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>{`${I18n.t('activerecord.attributes.flag.flag')} ${selectedLesson.id}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderLessonModalContent()}
        </Modal.Body>
      </Modal>
    ) : ""
  }

  const renderFiltersButton = () => {
    return (
      <FiltersButton
        onClick={() => setShowFilters(!showFilters)}
        aria-controls="collapse-filters"
        aria-expanded={showFilters}>
          <h4>{showFilters ? <BsBoxArrowUp /> : <BsBoxArrowDown />}</h4>
      </FiltersButton>
    )
  }

  const renderList = () => {
    return (selectedTab == "list") ?
      (
        <SortableTable
          data={filteredData}
          columns={tableColumns}
          useRenderFunction={true}
          cellContent={cellContent}
        />  
      ) : ""
  }

  return (
    <>
      {renderLessonModal()}
      <div>
        <Collapse in={showFilters}>
          <div id="collapse-filters">
            <MonitoringRequestFilters handleFilterChange={handleFilterChange} includedFilters={filters} excludeSearch={true} />
          </div>
        </Collapse>
        {renderFiltersButton()}
      </div>
      <div>
        <ExportWrapper data={filteredData} filename={I18n.t("headers.flag.tpm_export_filename")}>{I18n.t("headers.flag.export")}</ExportWrapper>
        <br/>
        {renderList()}
      </div>
    </>
  )
}

