import React from 'react'
import I18n from 'i18n-js/locales.js'
import { appConstants } from 'utils/constants.js'
import MonitoringRequestInfo from './MonitoringRequestInfo.js'
import Gallery from '../galleries/Gallery.js'
import SimpleList from '../lists/SimpleList.js'
import { Container, Row, Col } from 'react-bootstrap'

export default function MonitoringRequestDetails(props) {
  return (
    <Container>
      <Col>
        <Row>
          <SimpleList
            url={appConstants.RESOURCES_FOR_MONITORING_REQUEST_URL + String(props.monitoringRequestId)}
            title={I18n.t('activerecord.attributes.monitoring_request.linked_resources')} />
        </Row>
        <Row>
          <h5 className="mb-3">{I18n.t('activerecord.attributes.monitoring_request.visit_info')}</h5>
          <MonitoringRequestInfo
            monitoringRequestId={props.monitoringRequestId} />
        </Row>
      </Col>
    </Container>
  )
}