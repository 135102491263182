import React, { useEffect, useState }  from "react"
import axios from 'axios'
import styled from 'styled-components'
import { CSVLink } from 'react-csv'
import { Button } from 'react-bootstrap'
import moment from 'moment-timezone';
import { appConstants } from 'utils/constants.js'
import I18n from 'i18n-js/locales.js'

const ExportWrapper = styled(CSVLink)`
  left: 2%;
  position: relative;
  z-index: 0;
  `;

export default function ExportIpOutputs(props) {
  const [ipOutputsData, setIpOutputsData] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)

  const loadIpOutputsData = async () => {
    const res = await axios.get(`${appConstants.MONITORING_EVENT_IP_OUTPUTS_URL}${props.id}.json`)
    setIpOutputsData(res.data)
    setDataLoaded(true)
  }

  useEffect(() => {
    loadIpOutputsData()
    setDataLoaded(true)
  }, [props.id])
  
  useEffect(() => {
    setDataLoaded(false)
  }, [props.updated])

  return props.id && props.allowed ? (
    <>
      <Button onClick={loadIpOutputsData} hidden={dataLoaded} variant="outline-secondary">{I18n.t("headers.monitoring_request.refresh_ip_outputs_export_data")}</Button>
      <ExportWrapper data={ipOutputsData} hidden={!dataLoaded} filename={`${I18n.t("headers.monitoring_request.ip_outputs_export_filename")}_SV${props.id}_${moment().format('YYYY-MM-DD')}.csv`} headers={props.headers}>{I18n.t("headers.monitoring_request.export_ip_outputs")}</ExportWrapper>
    </>
  ) : ""
}