import React, { useState, useEffect }  from 'react'
import moment from 'moment'
import axios from 'axios'
import styled from 'styled-components'
import SortableTable from './tables/SortableTable.js'
import MonitoringRequestFilters from './filters/MonitoringRequestFilters'
import FlashReportForm from './forms/FlashReportForm.js'
import { appConstants } from 'utils/constants.js'
import I18n from 'i18n-js/locales.js'
import { CSVLink } from 'react-csv'
import Modal from 'react-bootstrap/Modal'
import Collapse from 'react-bootstrap/Collapse'
import { BsBoxArrowDown, BsBoxArrowUp } from 'react-icons/bs'
import Fuse from 'fuse.js'


const FiltersButton = styled.div`
  left: .5em;
  top: .1em;
  position: absolute;
  z-index: 0;
  cursor: pointer;
  color: #205492;
  `;

const ExportWrapper = styled(CSVLink)`
  left: 88%;
  top: -6px;
  position: relative;
  z-index: 0;
  `;

const searchOptions = {
  // https://fusejs.io/api/options.html for full list of options
  minMatchCharLength: 3,
  threshold: 0.1,
  distance: 500,
  keys: [
    "name",
    "id",
    "description",
    "beneficiaries",
    "request_justification_details",
    "poc",
    "location"
  ]
};

moment.updateLocale('en', {
  week: {
      dow: 5,
      doy: 1,
  },
});

export default function TPMFlashReportsArchive(props) {
  const [initialData, setInitialData] = useState(props.initialData.data || [])
  const [filteredData, setFilteredData] = useState(props.initialData.data || [])
  const [filters, setFilters] = useState(props.initialData.filters || [])
  const [tableColumns, setTableColumns] = useState(props.columns || [])

  const [viewModal, setViewModal] = useState(false)
  const [selectedRequestId, setSelectedRequestId] = useState('')
  const [showFilters, setShowFilters] = useState(true)
  const [searchTerms, setSearchTerms] = useState('')

  useEffect(() => {
    filterData(filters)
  }, [initialData, searchTerms])

  const refreshData = () => {
    axios.get(appConstants.ARCHIVE_URL)
    .then(res => {
      const monitoringRequests = res.data;
      setInitialData(monitoringRequests.data)
    })
    .catch(error => {
      console.log(error);
    })
  }

  // filtering functions
  const handleFilterChange = (e, filterBy) => {
    let filterValue = null
    if (e) { filterValue = e.value } 
    const newFilters = {
      ...filters,
      [filterBy]: filterValue,
    }
    filterData(newFilters)
  }

  const handleSearch = (e) => {
    setSearchTerms(e.target.value)
  }

  const filterData = (filters) => {
    const data = initialData
    const filterKeys = Object.keys(filters)
    let filteredData = [...data]
    for (let filterKey of filterKeys) {
      if (filters[filterKey] !== null) {
        if (filters[filterKey] == -1) {
          filteredData = filteredData.filter(task => (task[filterKey] == "" || task[filterKey] == null) )
        } else {
          filteredData = filteredData.filter(task => task[filterKey] == filters[filterKey] )
        }
        
      }
    }

    if (searchTerms.length > 2) {
      const fuse = new Fuse(filteredData, searchOptions);
      const searchResults = fuse.search(searchTerms);
      filteredData = searchResults.map(task => task.item)
    }
    
    setFilteredData(filteredData)
    setFilters(filters)
  }


  const requestModalLink = (cell, row) => {
    return (
      cell["column"]["id"] === "name"
        ? <a href='#' onClick={() => {editRequest(row["original"]["id"])}}>{row["original"]["name"]}</a>
        : cell.render('Cell') 
    )
  }

  // modal/form functions
  const editRequest = (taskId) => {
    setSelectedRequestId(taskId)
    setViewModal(true)
  }

  const closeModal = () => {
    setViewModal(false)
  }

  const renderMonitoringRequestModalContent = () => {
    return (
      <FlashReportForm col={6}
        allowed={props.allowed}
        id={selectedRequestId}
        authenticityToken={props.authenticityToken}
        refreshData={refreshData}
        closeModal={closeModal} />
    )
  }

  const renderRequestModal = () => {
    return (
      <Modal
        show={viewModal}
        onHide={closeModal}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
        <Modal.Title>{I18n.t('activerecord.attributes.monitoring_request.monitoring_event')} {selectedRequestId} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderMonitoringRequestModalContent()}
        </Modal.Body>
      </Modal>
    )
  }

  const renderFiltersButton = () => {
    return (
      <FiltersButton 
        onClick={() => setShowFilters(!showFilters)}
        aria-controls="collapse-filters" 
        aria-expanded={showFilters}>
          <h4>{showFilters ? <BsBoxArrowUp /> : <BsBoxArrowDown />}</h4>
      </FiltersButton>
    ) 
  }

  const renderList = () => {
    return (
      <SortableTable
        data={filteredData}
        columns={tableColumns}
        useRenderFunction={true}
        cellContent={requestModalLink}
      />  
    ) 
  }

  return (
    <>
      {renderRequestModal()}
      <div>
        <Collapse in={showFilters}>
          <div id="collapse-filters">
            <MonitoringRequestFilters handleFilterChange={handleFilterChange} handleSearch={handleSearch} includedFilters={filters} />
          </div>
        </Collapse>
        {renderFiltersButton()}
      </div>

      <div>
        <ExportWrapper data={filteredData} filename={I18n.t("headers.monitoring_request.flash_report_export_filename")}>{I18n.t("headers.monitoring_request.export")}</ExportWrapper>
        {renderList()}
      </div>
    </>
  )
}

