import React, { useState } from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { Form, Row, Col, Button, Alert, Table, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { BsInfoCircleFill } from "react-icons/bs";
import SelectMultiple from '../selects/SelectMultiple.js'
import { appConstants } from 'utils/constants.js'


export default function UserEmailPreferencesForm(props) {
  const [getsSvrEmails, setGetsSvrEmails] = useState(props.user.gets_svr_emails || false)
  const [getsIpInfoRequestEmails, setGetsIpInfoRequestEmails] = useState(props.user.gets_ip_info_request_emails || false)
  const [getsCompletedVisitsEmails, setGetsCompletedVisitsEmails] = useState(props.user.gets_completed_visits_emails || false)
  const [getsFlashReportEmails, setGetsFlashReportEmails] = useState(props.user.gets_flash_report_emails || false)
  const [getsLessonIpFeedbackEmails, setGetsLessonIpFeedbackEmails] = useState(props.user.gets_lesson_ip_feedback_emails || false)
  const [getsCompletedLessonsEmails, setGetsCompletedLessonsEmails] = useState(props.user.gets_completed_lessons_emails || false)
  const [getsIpOutputsRequestEmails, setGetsIpOutputsRequestEmails] = useState(props.user.gets_ip_outputs_request_emails || false)
  const [customizesEmails, setCustomizesEmails] = useState(props.user.customizes_emails || false)
  const [sectorIds, setSectorIds] = useState(props.user.customizes_emails && props.user.subscriptions["sector_ids"] ? props.user.subscriptions["sector_ids"].map(s=>parseInt(s)) : [])
  const [stateIds, setStateIds] = useState(props.user.customizes_emails && props.user.subscriptions["governorate_ids"] ? props.user.subscriptions["governorate_ids"].map(g=>parseInt(g)) : [])
  const [alertMessage, setAlertMessage] = useState('')


  const createFormData = function()  {
    let formData = new FormData()
    formData.append('user[gets_svr_emails]', getsSvrEmails || false)
    formData.append('user[gets_ip_info_request_emails]', getsIpInfoRequestEmails || false)
    formData.append('user[gets_completed_visits_emails]', getsCompletedVisitsEmails || false)
    formData.append('user[gets_flash_report_emails]', getsFlashReportEmails || false)
    formData.append('user[gets_lesson_ip_feedback_emails]', getsLessonIpFeedbackEmails || false)
    formData.append('user[gets_completed_lessons_emails]', getsCompletedLessonsEmails || false)
    formData.append('user[gets_ip_outputs_request_emails]', getsIpOutputsRequestEmails || false)
    formData.append('user[customizes_emails]', customizesEmails || false)
    sectorIds.forEach(s => { formData.append('user[subscriptions][sector_ids][]', s) })
    stateIds.forEach(s => { formData.append('user[subscriptions][governorate_ids][]', s) })
    
    return formData
  }

  const handleSubmit = async () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const data = createFormData()
    const res = await axios.put(`${appConstants.USER_PREFERENCES_URL}/${props.user.id}.json`, data )
    const notice = res.status === 200 ? I18n.t("notices.update_success") : I18n.t("notices.failure")
    setAlertMessage(notice)
  }

  const renderTooltip = (msg) => (
    <Tooltip id="info-tooltip">
      <span className='email-guidance-tooltip'>{ I18n.t(`user.email_guidance.${msg}`) }</span>
    </Tooltip>
  )

  const renderInfoIcon = (msg) => {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(msg)}>
        <Button variant="link" className='p-0'><BsInfoCircleFill /></Button>
      </OverlayTrigger>
    )
  }

  const renderAlertMessage = () => {
    return alertMessage ? (
      <Alert variant={'info'} onClose={() => setAlertMessage("")} dismissible>
        {alertMessage}
      </Alert>
    ) : ""
  }

  return  (
    <Form>
      <Row>
        <Col>
          {renderAlertMessage()}
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Table>
            <tbody>
              { props.allowed.svr_emails ? (
                <tr>
                  <td><Form.Label className="mb-0">{I18n.t('activerecord.attributes.user.email_preferences.svr')}</Form.Label></td>
                  <td><Form.Check
                    disabled={false}
                    type="switch"
                    name="getsSvrEmails"
                    checked={getsSvrEmails}
                    value={true}
                    onChange={() => setGetsSvrEmails(!getsSvrEmails)}
                    className='pt-1'
                    bsPrefix="getsSvrEmails-switch" />
                  </td>
                  <td>{renderInfoIcon('ip_feedback')}</td>
                </tr>
              )  : "" }
              { props.allowed.ip_info_request_emails ? (
                <tr>
                  <td><Form.Label className="mb-0">{I18n.t('activerecord.attributes.user.email_preferences.ip_info_request')}</Form.Label></td>
                  <td><Form.Check
                    disabled={false}
                    type="switch"
                    name="getsIpInfoRequestEmails"
                    checked={getsIpInfoRequestEmails}
                    value={true}
                    onChange={() => setGetsIpInfoRequestEmails(!getsIpInfoRequestEmails)}
                    className='pt-1'
                    bsPrefix="getsIpInfoRequestEmails-switch" />
                  </td>
                  <td>{renderInfoIcon('ip_info_request')}</td>
                </tr>
              )  : "" }
              { props.allowed.completed_visits_emails ? (
                <tr>
                  <td><Form.Label className="mb-0">{I18n.t('activerecord.attributes.user.email_preferences.completed_visits')}</Form.Label></td>
                  <td><Form.Check
                    disabled={false}
                    type="switch"
                    name="getsCompletedVisitsEmails"
                    checked={getsCompletedVisitsEmails}
                    value={true}
                    onChange={() => setGetsCompletedVisitsEmails(!getsCompletedVisitsEmails)}
                    className='pt-1'
                    bsPrefix="getsCompletedVisitsEmails-switch" />
                  </td>
                  <td>{renderInfoIcon('completed_visits_digest')}</td>
                </tr>
              )  : "" }
              { props.allowed.flash_report_emails ? (
                <tr>
                  <td><Form.Label className='mb-0'>{I18n.t('activerecord.attributes.user.email_preferences.flash_report')}</Form.Label></td>
                  <td><Form.Check
                    disabled={false}
                    type="switch"
                    name="getsFlashReportEmails"
                    checked={getsFlashReportEmails}
                    value={true}
                    onChange={() => setGetsFlashReportEmails(!getsFlashReportEmails)}
                    className='pt-1'
                    bsPrefix="getsFlashReportEmails-switch" />
                  </td>
                  <td>{renderInfoIcon('flash_report')}</td>
                </tr>
              )  : "" }
              { props.allowed.lesson_ip_feedback_emails ? (
                <tr>
                  <td><Form.Label className="mb-0">{I18n.t('activerecord.attributes.user.email_preferences.lesson_ip_feedback')}</Form.Label></td>
                  <td><Form.Check
                    disabled={false}
                    type="switch"
                    name="getsLessonIpFeedbackEmails"
                    checked={getsLessonIpFeedbackEmails}
                    value={true}
                    onChange={() => setGetsLessonIpFeedbackEmails(!getsLessonIpFeedbackEmails)}
                    className='pt-1'
                    bsPrefix="getsLessonIpFeedbackEmails-switch" />
                  </td>
                  <td>{renderInfoIcon('lesson_ip_feedback')}</td>
                </tr>
              )  : "" }
              { props.allowed.completed_lessons_emails ? (
                <tr>
                  <td><Form.Label className="mb-0">{I18n.t('activerecord.attributes.user.email_preferences.completed_lessons')}</Form.Label></td>
                  <td><Form.Check
                    disabled={false}
                    type="switch"
                    name="getsCompletedLessonsEmails"
                    checked={getsCompletedLessonsEmails}
                    value={true}
                    onChange={() => setGetsCompletedLessonsEmails(!getsCompletedLessonsEmails)}
                    className='pt-1'
                    bsPrefix="getsCompletedLessonsEmails-switch" />
                  </td>
                  <td>{renderInfoIcon('completed_lessons_digest')}</td>
                </tr>
              )  : "" }
              { props.allowed.ip_outputs_request_emails ? (
                <tr>
                  <td><Form.Label className="mb-0">{I18n.t('activerecord.attributes.user.email_preferences.ip_outputs_request')}</Form.Label></td>
                  <td><Form.Check
                    disabled={false}
                    type="switch"
                    name="getsIpOutputsRequestEmails"
                    checked={getsIpOutputsRequestEmails}
                    value={true}
                    onChange={() => setGetsIpOutputsRequestEmails(!getsIpOutputsRequestEmails)}
                    className='pt-1'
                    bsPrefix="getsIpOutputsRequestEmails-switch" />
                  </td>
                  <td>{renderInfoIcon('ip_outputs_request')}</td>
                </tr>
              )  : "" }
              { props.allowed.customizes_emails ? (
                <tr>
                  <td><Form.Label className="mb-0">{`${I18n.t('activerecord.attributes.user.email_preferences.filter_subscriptions')}?`}</Form.Label></td>
                  <td><Form.Check
                    disabled={false}
                    type="switch"
                    name="customizesEmails"
                    checked={customizesEmails}
                    value={true}
                    onChange={() => setCustomizesEmails(!customizesEmails)}
                    className='pt-1'
                    bsPrefix="customizesEmails-switch" />
                  </td>
                  <td>{renderInfoIcon('notification_filters')}</td>
                </tr>
              )  : "" }
            </tbody>
          </Table>
        </Col>
        { customizesEmails ? 
          (
            <Col md={6}>
              <Row>
                <Form.Group className="mb-3" controlid="preferencesForm.sectors">
                    <Form.Label>{I18n.t('activerecord.attributes.user.email_preferences.subscriptions.sectors')}</Form.Label>
                    <SelectMultiple
                      isDisabled={false}
                      selected={sectorIds}
                      onChange={(e) => setSectorIds(e ? e.map(i => i.value) : [])}
                      url={appConstants.SECTORS_URL}
                      isClearable={true}
                      placeholder={I18n.t('selects.multiple_optional')} />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlid="preferencesForm.states">
                    <Form.Label>{I18n.t('activerecord.attributes.user.email_preferences.subscriptions.states')}</Form.Label>
                    <SelectMultiple
                      isDisabled={false}
                      selected={stateIds}
                      onChange={(e) => setStateIds(e ? e.map(i => i.value) : [])}
                      url={appConstants.GOVERNORATES_URL}
                      isClearable={true}
                      placeholder={I18n.t('selects.multiple_optional')} />
                </Form.Group>
              </Row>
            </Col>
          ) : ""
        }
      </Row>
      <Form.Group className="mb-3" controlid="requestForm.buttons">
        <Button disabled={false} variant="outline-primary" onClick={() => handleSubmit()}>{I18n.t("navigation.update_preferences")}</Button> &nbsp;
      </Form.Group>
      
    </Form>
  )
}