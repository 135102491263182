import React, { useState }  from "react"
import ResourceTable from './tables/ResourceTable.js'
import MonitoringRequestFilters from "./filters/MonitoringRequestFilters.js"

export default function SiteVisitResourcePage(props) {
  const [filters, setFilters] = useState(props.filters)
  const [initialResources, setInitialResources] = useState(props.resources)
  const [filteredResources, setFilteredResources] = useState(props.resources) 

  // filtering functions
  const handleFilterChange = (e, filterBy, clickClear) => {
    let filterValue = null
    if (e && (filters[filterBy] != e.value || !clickClear)) { filterValue = e.value }
    const newFilters = {
      ...filters,
      [filterBy]: filterValue,
    }
    filterData(newFilters)
  }

  const filterData = (filters) => {
    const data = initialResources
    const filterKeys = Object.keys(filters)
    let filteredData = [...data]
    for (let filterKey of filterKeys) {
      if (filters[filterKey]) {
        if (filters[filterKey] == -1) {
          filteredData = filteredData.filter(resource => (resource[filterKey] == "" || resource[filterKey] == null) )
        } else {
          filteredData = filteredData.filter(resource => resource[filterKey] == filters[filterKey] )
        }
      }
    }
    setFilters(filters)
    setFilteredResources(filteredData)
  }

  return (
    <>
      <MonitoringRequestFilters
        includedFilters={filters}
        handleFilterChange={handleFilterChange}
        excludeSearch={true} />
      <ResourceTable
        resources={filteredResources}
        columns={props.columns}
        canDownload={props.canDownload} />
    </>
  )
}