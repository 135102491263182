import React, { useState }  from "react"
import { Modal } from 'react-bootstrap'
import I18n from 'i18n-js/locales.js'
import SortableTable from './SortableTable.js'
import ResourceDetails from '../details/ResourceDetails.js'

export default function ResourceTable(props) {
  const [selectedResource, setSelectedResource] = useState({})

  const closeResource = () => {
    setSelectedResource({})
  }

  const defaultColumns = [
    {
      "Header": I18n.t('activerecord.attributes.resource.name'),
      "accessor": "name"
    },
    {
      "Header": I18n.t('activerecord.attributes.resource.updated_at'),
      "accessor": "updated_at"
    }
  ]

  const resourceLink = (cell, row) => {
    const className = selectedResource.id == row["original"]["id"] ? "fw-bold" : ""
    return (
      cell["column"]["id"] === "name"
        ? <a href='#' className={className} onClick={() => {setSelectedResource(row.original)}}>{row["original"]["name"]}</a>
        : cell.render('Cell') 
    )
  }

  const renderResourceModal = () => {
    return Object.keys(selectedResource).length > 0 ? (
      <Modal
        show={Object.keys(selectedResource).length > 0}
        onHide={closeResource}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>{`${selectedResource.name ? selectedResource.name : ""}` }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResourceDetails id={selectedResource.id} exitResource={() => closeResource} showCollection={false} collection={{}} showSiteVisitDetails={true} canDownload={props.canDownload} />
        </Modal.Body>
      </Modal>
    ) : ""
  }

  return (
    <>
      {renderResourceModal()}
      <div className="mt-3">
        <SortableTable
          data={props.resources}
          columns={props.columns || defaultColumns}
          useRenderFunction={true}
          cellContent={resourceLink}
        />
      </div>
    </>
  )
}
