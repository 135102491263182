import React, { useEffect, useState }  from "react"
import { Card, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import { setResource, appConstants } from 'utils/constants.js'
import SortableTable from './SortableTable.js'
import ResourceDetails from '../details/ResourceDetails.js'

export default function LessonResourceTable(props) {
  const [resources, setResources] = useState([])
  const [columns, setColumns] = useState([])
  const [selectedResource, setSelectedResource] = useState({})

  const loadResources = async () => {
    const res = await axios.get(appConstants.LESSON_RESOURCES_URL + String(props.lessonId))
    setResources(res.data.resources)
    setColumns(res.data.columns)
  }

  useEffect(() => {
    // TODO: update this so it still loads when the site visit has resources, but only REloads when the resource form is submitted
    loadResources()
  }, [props.id, props.refresh])

  const resourceLink = (cell, row) => {
    const className = selectedResource.id == row["original"]["id"] ? "fw-bold" : ""
    return (
      cell["column"]["id"] === "name"
        ? <a href='#' className={className} onClick={() => {setSelectedResource(setResource(row.original))}}>{row["original"]["name"]}</a>
        : cell.render('Cell') 
    )
  }

  const renderResource = () => {
    return Object.keys(selectedResource).length > 0 ? (
      <div>
        <Card.Title>{`${selectedResource.name ? selectedResource.name : ""}` }</Card.Title>
        <Card.Body>
          <ResourceDetails id={selectedResource.id} exitResource={() => setSelectedResource({})} showCollection={false} collection={{}} showSiteVisitDetails={false} canDownload={props.canDownload} />
        </Card.Body>
      </div>
    ) : ""
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <SortableTable
            data={resources}
            columns={columns}
            useRenderFunction={true}
            cellContent={resourceLink}
          />
        </Col>
        <Col md={12}>
          {renderResource()}
        </Col>
      </Row>
    </>
  )
}
