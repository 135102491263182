import React, { Component }  from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { formatValidationErrors } from 'utils/format.js'
import { appConstants } from 'utils/constants.js'
import SelectMultiple from '../selects/SelectMultiple.js'
import { Form, Row, Col, Button, Alert } from 'react-bootstrap'

export default class IPForm extends Component {

  state = {
    id: this.props.activity.id || "",
    name: this.props.activity.name || "",
    abbreviation: this.props.activity.abbreviation || "",
    agreementIDs: this.props.activity.agreements.map(i => (i.id)) || [],
    alertMessage: "",
  }


  createFormData = () => {
    let formData = new FormData()
    formData.append('activity[name]', this.state.name)
    formData.append('activity[abbreviation]', this.state.abbreviation)
    this.state.agreementIDs.length > 0
      ? this.state.agreementIDs.forEach(agreementID => {
        formData.append('activity[agreement_ids][]', agreementID)
      })
      : formData.set('activity[agreement_ids][]', "")

    return formData
  }

  handleSubmit = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    const ip = this.createFormData()
    if (this.state.id) {
      axios.put(`${appConstants.ACTIVITIES_URL}/${this.state.id}`, ip )
      .then(res => {
        return window.location.href = appConstants.ACTIVITIES_URL
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('implementing_partner', error.response.data) });
      })
    } else {
      axios.post(appConstants.ACTIVITIES_URL, ip )
      .then(res => {
        return window.location.href = appConstants.ACTIVITIES_URL
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('implementing_partner', error.response.data) });
      })
    }
  }


  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value
    })
  }

  handleAgreementsChange = (e) => {
    const agreementIDs = e != null ? e.map(t=> t.value) : []
    this.setState({
      agreementIDs: agreementIDs
    })
  }

  
  handleAlertMessage = () => {
    if (this.state.alertMessage){
      return (
        <Alert variant={'info'} onClose={() => this.setState({ alertMessage: null })} dismissible>
          {this.state.alertMessage}
        </Alert>
      )
    } else {
      return ""
    }
  };


  render() {
    return (
      <Form>
        <Row>
          <Col>
            {this.handleAlertMessage()}
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col} md={5} className="mb-3" controlId="name">
          <Form.Label>{I18n.t('activerecord.attributes.activity.name')}<span className="text-danger">*</span></Form.Label>
            <Form.Control
              name="name"
              type="text"
              value={this.state.name}
              onChange={this.handleInputChange} />
          </Form.Group>

          <Form.Group as={Col} md={2} className="mb-3" controlId="abbreviation">
            <Form.Label>{I18n.t('activerecord.attributes.activity.abbreviation')}<span className="text-danger">*</span></Form.Label>
            <Form.Control
              name="abbreviation"
              type="text"
              value={this.state.abbreviation}
              onChange={this.handleInputChange} />
          </Form.Group>

          <Form.Group as={Col} md={5} controlId="agreements">
            <Form.Label>{I18n.t('activerecord.attributes.activity.agreements')}</Form.Label>
            <SelectMultiple
              selected={this.state.agreementIDs}
              onChange={this.handleAgreementsChange}
              url={appConstants.AGREEMENTS_URL + ".json"}
              placeholder={I18n.t('selects.multiple_optional')} />
          </Form.Group>
        </Row>


        <Form.Group className="mb-3">
          <Button variant="outline-primary" onClick={this.handleSubmit}>{this.state.id ? I18n.t('navigation.update') : I18n.t('navigation.add')}</Button> &nbsp;
          <Button variant="outline-secondary" href={appConstants.ACTIVITIES_URL}>{I18n.t("navigation.cancel")}</Button>
        </Form.Group>
      </Form>
    );
  }
}
