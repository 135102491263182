import React, { Component }  from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { formatValidationErrors } from 'utils/format.js'
import { appConstants } from 'utils/constants.js'
import SelectSingle from '../selects/SelectSingle.js'
import SelectMultiple from '../selects/SelectMultiple.js'
import { Form, Row, Col, Button, Alert } from 'react-bootstrap'

export default class AgreementForm extends Component {

  state = {
    id: this.props.agreement.id || "",
    name: this.props.agreement.name || "",
    activityID: this.props.agreement.activity_id || "",
    monitoringRequestIDs: this.props.agreement.monitoring_requests.map(i => (i.id)) || [],
    ipPlanIDs: this.props.agreement.ip_plans.map(i => (i.id)) || [],
    alertMessage: "",
  }


  createFormData = () => {
    let formData = new FormData()
    formData.append('agreement[name]', this.state.name)
    formData.append('agreement[activity_id', this.state.activityID)
    this.state.monitoringRequestIDs.length > 0
      ? this.state.monitoringRequestIDs.forEach(mrId => {
        formData.append('agreement[monitoring_request_ids][]', mrId)
      })
      : formData.set('agreement[monitoring_request_ids][]', "")

    this.state.ipPlanIDs.length > 0
      ? this.state.ipPlanIDs.forEach(ippId => {
        formData.append('agreement[ip_plan_ids][]', ippId)
      })
      : formData.set('agreement[ip_plan_ids][]', "")

    return formData
  }

  handleSubmit = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    const agreement = this.createFormData()
    if (this.state.id) {
      axios.put(`${appConstants.AGREEMENTS_URL}/${this.state.id}`, agreement )
      .then(res => {
        return window.location.href = appConstants.AGREEMENTS_URL
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('agreement', error.response.data) });
      })
    } else {
      axios.post(appConstants.AGREEMENTS_URL, agreement )
      .then(res => {
        return window.location.href = appConstants.AGREEMENTS_URL
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('agreement', error.response.data) });
      })
    }
  }

  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value
    })
  }

  handleSelectChange = (e, selectName) => {
    let selectValue = null
    if (e) { selectValue = e.value } 
    this.setState({
      [selectName]: selectValue
    })
  }

  handleMultiChange = (e, field) => {
    const ids = e != null ? e.map(i => i.value) : []
    this.setState({
      [field]: ids
    })
  }

  handleAlertMessage = () => {
    if (this.state.alertMessage){
      return (
        <Alert variant={'info'} onClose={() => this.setState({ alertMessage: null })} dismissible>
          {this.state.alertMessage}
        </Alert>
      )
    } else {
      return ""
    }
  };


  render() {
    return (
      <Form>
        <Row>
          <Col>
            {this.handleAlertMessage()}
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col} md={6} className="mb-3" controlId="agreementForm.data_name">
          <Form.Label>{I18n.t('activerecord.attributes.agreement.name')}<span className="text-danger">*</span></Form.Label>
          <Form.Control
            name="name"
            type="text"
            value={this.state.name}
            onChange={this.handleInputChange} />
          </Form.Group>

          <Form.Group as={Col} md={6} className="mb-3" controlId="agreementForm.activity">
            <Form.Label>{I18n.t('activerecord.attributes.agreement.activity')}</Form.Label>
            <SelectSingle
              selected={this.state.activityID}
              onValueChange={(e) => this.handleSelectChange(e, "activityID")}
              url={appConstants.ACTIVITIES_URL + ".json"}
              isClearable={true}
              placeholder={I18n.t('selects.single')} />
          </Form.Group>

          <Form.Group as={Col} md={6} className="mb-3" controlId="agreementForm.monitoring_requests">
            <Form.Label>{I18n.t('activerecord.attributes.agreement.monitoring_requests')}</Form.Label>
            <SelectMultiple
              selected={this.state.monitoringRequestIDs}
              onChange={(e) => this.handleMultiChange(e, 'monitoringRequestIDs')}
              url={appConstants.MONITORING_REQUESTS_URL + ".json"}
              placeholder={I18n.t('selects.multiple_optional')} />
          </Form.Group>

          <Form.Group as={Col} md={6} className="mb-3" controlId="agreementForm.ip_plans">
            <Form.Label>{I18n.t('activerecord.attributes.agreement.ip_plans')}</Form.Label>
            <SelectMultiple
              selected={this.state.ipPlanIDs}
              onChange={(e) => this.handleMultiChange(e, 'ipPlanIDs')}
              url={appConstants.IP_PLANS_URL}
              placeholder={I18n.t('selects.multiple_optional')} />
          </Form.Group>
        </Row>


        <Form.Group className="mb-3">
          <Button variant="outline-primary" onClick={this.handleSubmit}>{this.state.id ? I18n.t('navigation.update') : I18n.t('navigation.add')}</Button> &nbsp;
          <Button variant="outline-secondary" href={appConstants.AGREEMENTS_URL}>{I18n.t("navigation.cancel")}</Button>
        </Form.Group>
      </Form>
    );
  }
}
