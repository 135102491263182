import React, { useEffect, useState }  from "react"
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { appConstants } from 'utils/constants.js'
import { Button, Toast, ToastContainer, Table, Badge } from 'react-bootstrap'
import { BiBell, BiXCircle } from "react-icons/bi";

export default function Notifications(props) {
  const [open, setOpen] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [notificationsCount, setNotificationsCount] = useState(props.initialCount)
  const [viewed, setViewed] = useState([])
  const [badgeColor, setBadgeColor] = useState(props.initialCount > 0 ? "danger" : "secondary")

  const fetchData = async () => {
    const response = await axios.get(`${appConstants.NOTIFICATIONS_LIST_URL}/${props.user.id}.json`)
    setNotifications(response.data)
  }

  useEffect(() => {
    if (open) {
      const markStale = async () => {
        axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
        const response = await axios.put(`${appConstants.STALE_NOTIFICATIONS_URL}/${props.user.id}`)
      }
      markStale().then(() => fetchData())
    }
  }, [open])

  useEffect(() => {
    if (viewed.length > 0) {
      const markViewed = async () => {
        axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
        const response = await axios.put(`${appConstants.VIEWED_NOTIFICATIONS_URL}`, {viewed: viewed})//.then(() => fetchData()).catch(err => console.log(err))
      }
      markViewed().then(() => fetchData()).catch(err => console.log(err))
    }
  }, [viewed])

  useEffect(() => {
    if (open) {
      setNotificationsCount(notifications.length)
      setBadgeColor(notifications.length > 0 ? "danger" : "secondary")
    }
  }, [notifications])

  const handleOpenLink = (n) => {
    setViewed([...viewed, n.id])
    window.location.href = n.comment_object_link
  }

  const renderNotification = (n) => {
    return (
      <tr id={n.id} key={n.id}>
        <td>{I18n.t('activerecord.attributes.notification.response_to_feedback')} <a href="#" onClick={() => handleOpenLink(n)}>{n.comment_object_name}</a></td>
        <td><Button aria-label="dismiss-notification" variant="link" className="float-end px-0 py-2" onClick={() => setViewed([...viewed, n.id])}><BiXCircle/></Button></td>
      </tr>
    )
  }

  return (
    <div>
      <Badge bg={badgeColor} className="notifications-badge p-0 d-flex">{notificationsCount > 0 ? <span className="py-3 ps-3">{notificationsCount}</span> : "" }<span><Button variant="link" size="lg" className="nav-link pt-1" onClick={() => setOpen(!open)}><BiBell/></Button></span></Badge>
      <ToastContainer
          className="p-3 mt-5"
          position={'top-end'}
          style={{ zIndex: 1 }}>
        <Toast animation={true} show={open} onClose={() => setOpen(false)}>
          <Toast.Header><strong className="me-auto">{I18n.t('activerecord.attributes.notification.your_notifications')}</strong></Toast.Header>
          <Toast.Body>
            <Table>
              <tbody>
                {notifications.map(n => renderNotification(n))}
              </tbody>
            </Table>
              <div className="text-center">
                { notifications.length > 0
                  ? <Button className="p-0" variant="link" size="sm" onClick={() => setViewed(notifications.map(n=>n.id))}>{I18n.t('activerecord.attributes.notification.dismiss_all')}</Button>
                  : I18n.t('activerecord.attributes.notification.no_notifications')
                }
              </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  )
}