import React, { useState, useEffect } from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { Form, Row, Col, Button, Alert, InputGroup, FormControl } from 'react-bootstrap'
import SelectMultiple from '../selects/SelectMultiple.js'
import { appConstants } from 'utils/constants.js'

export default function LessonEmailForm(props) {
  const [lessons, setLessons] = useState(props.lessons || [])
  const [chosenLessons, setChosenLessons] = useState(props.lessons || [])
  const [recipients, setRecipients] = useState([])
  const [alertMessage, setAlertMessage] = useState("")

  useEffect(() => {
    const loadRecipients = async () => {
      const response = await axios.get(appConstants.COMPLETED_LESSONS_DIGEST_USERS_URL)
      setRecipients(response.data.map(r => r.id) || [])
    }
    loadRecipients()
  }, [])

  const handleSend = async () => {
    if (recipients.length < 1) {
      setAlertMessage(I18n.t('errors.email_missing'))
      return false
    }

    if (chosenLessons.length < 1) {
      setAlertMessage(I18n.t('errors.lesson_missing'))
      return false
    }

    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;

    const postBody = {
      lessons: chosenLessons,
      users: recipients
    }

    axios.post(`${appConstants.SEND_COMPLETED_LESSONS_URL}`, postBody)
    .then(res => {
      setAlertMessage(I18n.t("notices.email_sent"))
      let remaining = lessons.filter(v => !chosenLessons.includes(v))
      setLessons(remaining);
      props.refreshData();
    })
    .catch(err => {
      setAlertMessage(I18n.t("notices.email_failed"))
    })
  }

  const handleAlertMessage = () => {
    if (alertMessage){
      return (
        <Alert variant={'info'} onClose={() => setAlertMessage(null)} dismissible>
          {alertMessage}
        </Alert>
      )
    } else {
      return ""
    }
  };

  const handleLessonChoice = (lesson) => {
    let choices = Object.assign([], chosenLessons)
    if (choices.indexOf(lesson) == -1) {
      choices.push(lesson);
    } else {
      choices.splice(choices.indexOf(lesson), 1);
    };

    if (choices !== chosenLessons) {
      setChosenLessons(choices);
    };
  }

  const renderLesson = (lesson, index) => {
    return (
      <InputGroup className="mb-1" key={`${lesson.id}_input`}>
        <InputGroup.Checkbox
          id={`lesson_${lesson.id}`}
          name="send_email"
          checked={chosenLessons.indexOf(lesson) !== -1}
          value={true}
          onChange={() => handleLessonChoice(lesson)}
          className="form-check-input mt-0" />
        <FormControl className="completed-lesson-list-id" placeholder={lesson.id} disabled={true} />
        <FormControl placeholder={lesson.name} disabled={true} />
      </InputGroup>
    )
  }

  return (
    <Form>
      <Row>
        <Col>
          {handleAlertMessage()}
        </Col>
      </Row>

      <h5>{I18n.t('headers.completed_lesson_emails.lessons_to_include')}</h5>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="emailForm.lessons">
          {lessons.map((l, i) => renderLesson(l,i))}
        </Form.Group>
      </Row>

      <h5>{I18n.t('headers.completed_lesson_emails.recipients')}</h5>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="emailForm.recipients">
          <SelectMultiple
            selected={recipients}
            onChange={(e) => setRecipients(e ? e.map(i => i.value) : [])}
            url={appConstants.COMPLETED_LESSONS_DIGEST_USERS_URL}
            placeholder={I18n.t('selects.multiple_optional')} />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" controlId="emailForm.buttons">
        <Button variant="outline-primary" onClick={handleSend}>{I18n.t("navigation.send")}</Button> &nbsp;
        <Button variant="outline-secondary" onClick={props.closeModal}>{I18n.t("navigation.cancel")}</Button>
      </Form.Group>
    </Form>
  );
};