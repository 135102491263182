import React, { useEffect }  from "react";
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FaSearch } from "react-icons/fa";
import { BiReset, BiStar } from "react-icons/bi";
import I18n from 'i18n-js/locales.js'

export default function SearchBar(props) {
  useEffect(() => {
    document.getElementById('searchbar').value = props.searchValue;
  }, [props.searchValue]); 

  return (
    <InputGroup>
      <FormControl
        placeholder={props.searchValue || I18n.t("search.placeholder")}
        id="searchbar"
      />
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-search">
              {I18n.t("search.search_button")}
            </Tooltip>
          }>
            <Button variant="outline-secondary" onClick={props.handleChange}><FaSearch /></Button>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-reset">
              {I18n.t("search.reset_button")}
            </Tooltip>
          }>
            <Button variant="outline-secondary" onClick={props.handleReset}><BiReset /></Button>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-save">
              {I18n.t("search.saved_searches_button")}
            </Tooltip>
          }>
            <Button variant="outline-secondary" onClick={props.handleSavedSearches}><BiStar /></Button>
        </OverlayTrigger>
    </InputGroup>
  );
}
