import React, { useState, useEffect }  from 'react'
import moment from 'moment'
import axios from 'axios'
import styled from 'styled-components'
import Board from './boards/Board'
import SortableTable from './tables/SortableTable.js'
import MonitoringRequestFilters from './filters/MonitoringRequestFilters'
import FlashReportForm from './forms/FlashReportForm.js'
import { appConstants, badgeAttributes } from 'utils/constants.js'
import I18n from 'i18n-js/locales.js'
import { Modal, Collapse, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap'
import { BsBoxArrowDown, BsBoxArrowUp } from 'react-icons/bs'
import Fuse from 'fuse.js'
import { BiLinkExternal } from 'react-icons/bi'


const FiltersButton = styled.div`
  left: .5em;
  top: .1em;
  position: absolute;
  z-index: 0;
  cursor: pointer;
  color: #205492;
  `;

const searchOptions = {
  // https://fusejs.io/api/options.html for full list of options
  minMatchCharLength: 3,
  threshold: 0.1,
  distance: 300,
  keys: [
    "name",
    "id",
    "description",
    "beneficiaries",
    "request_justification_details",
    "poc",
  ]
}

export default function TPMFlashReports(props) {
  const [initialData, setInitialData] = useState(props.initialData.data || [])
  const [filteredData, setFilteredData] = useState(props.initialData.data || [])
  const [filters, setFilters] = useState(props.initialData.filters || [])
  const [tableColumns, setTableColumns] = useState(props.initialData.columns || [])
  const [flashReportingBoard, setFlashReportingBoard] = useState(props.initialData.flash_reporting_board)

  const [viewModal, setViewModal] = useState(props.monitoringRequestId ? true : false)
  const [selectedRequestId, setSelectedRequestId] = useState(props.monitoringRequestId || '')
  const [showFilters, setShowFilters] = useState(true)
  const [searchTerms, setSearchTerms] = useState('')

  useEffect(() => {
    setFlashReportingBoard(setBoardData(flashReportingBoard, initialData))
  }, [initialData])

  useEffect(() => {
    filterData(filters, flashReportingBoard)
  }, [initialData, searchTerms])

  const refreshData = () => {
    axios.get(appConstants.FLASH_BOARD_URL + ".json")
    .then(res => {
      const monitoringRequests = res.data;
      setInitialData(monitoringRequests.data)
    })
    .catch(error => {
      console.log(error);
    })
  }

  // filtering functions
  const handleFilterChange = (e, filterBy, clickClear) => {
    let filterValue = null
    if (e && (filters[filterBy] != e.value || !clickClear)) { filterValue = e.value }
    const newFilters = {
      ...filters,
      [filterBy]: filterValue,
    }
    filterData(newFilters, flashReportingBoard)
  }

  const handleSearch = (e) => {
    setSearchTerms(e.target.value)
  }

  const filterData = (filters, board) => {
    const data = initialData
    const filterKeys = Object.keys(filters)
    let filteredData = [...data]
    for (let filterKey of filterKeys) {
      if (filters[filterKey]) {
        if (filters[filterKey] == -1) {
          filteredData = filteredData.filter(task => (task[filterKey] == "" || task[filterKey] == null) )
        } else {
          filteredData = filteredData.filter(task => task[filterKey] == filters[filterKey] )
        }
        
      }
    }
    if (searchTerms.length > 2) {
      const fuse = new Fuse(filteredData, searchOptions);
      const searchResults = fuse.search(searchTerms);
      filteredData = searchResults.map(task => task.item)
    }

    const flashReportingBoard = setBoardData(board, filteredData)
    setFilteredData(filteredData)
    setFlashReportingBoard(flashReportingBoard)
    setFilters(filters)
  }

  // board functions
  const setBoardData = (board, data) => {
    const columnKeys = Object.keys(board.columns)
    let newColumns = board.columns
    let newTasks = {}
    for (let key of columnKeys) {
      newColumns[key]["taskIds"] = []
    }
    data.map(task => {
      if (newColumns[task.flash_report_status]) {
        task.id = task.id.toString()
        newTasks[task.id] = task
        newColumns[task.flash_report_status]["taskIds"].push(task.id)
      }
    })
 
    const newBoard = {
      ...board,
      tasks: newTasks,
      columns: newColumns,
    }
    return newBoard
  }

  const dropDisabled = (index, columnId, homeIndex, multidirectional) => {
    if (multidirectional && props.allowed.edit_flash_report) {
      return false
    } else if (!props.allowed.edit_flash_report) {
      return true
    } else {
      return index < homeIndex
    }
  }

  const taskContent = (task) => {
    const lastStatus = task.request_status_id == 'submitted' ? I18n.t('activerecord.attributes.monitoring_request.date_submitted') : 'Status Updated'
    const virtual = task.virtual ? <span id={Math.random()}><i className="fa fa-phone"></i></span> : ''
    const visitPurposeBadge = <Badge pill className={badgeAttributes[task.visit_purpose]["class"]}>{badgeAttributes[task.visit_purpose]["text"]}</Badge>

    return (
      <div className="task-content">
        <strong><a href='#' onClick={() => {editRequest(task.id)}}>{task.id}. {task.name}</a></strong> {virtual} {renderLinkedSiteSelector(task.fulcrum_id)}<br/>
        {lastStatus}: {task.date_submitted} &nbsp; {visitPurposeBadge} <br/>
      </div>
    )
  }

  const renderLinkedSiteSelector = (fulcrum_id) => {
    return (props.allowed.edit_flash_report && fulcrum_id && fulcrum_id.length > 0) ? (
      <span><a href={appConstants.FULCRUM_URL + "records/" + fulcrum_id} target="_blank" rel="noopener noreferrer" key={fulcrum_id}><BiLinkExternal /></a>&nbsp;</span>
    ) : ""
  }

  const renderLinkedRecords = (records) => {
    return (props.allowed.edit_flash_report) ? (
      <>
        {records.map((record) => 
          <OverlayTrigger 
            key={record} 
            placement='top'
            overlay={
              <Tooltip id={`tooltip-${record}`}>
                {record}
              </Tooltip>
            }
          >
            <a href={appConstants.FULCRUM_URL + "records/" + record} target="_blank" rel="noopener noreferrer" key={record}><BiLinkExternal /></a>
          </OverlayTrigger>
        )}
     </>
     ) : ""
  }

  const requestModalLink = (cell, row) => {
    return (
      cell["column"]["id"] === "name"
        ? <a href='#' onClick={() => {editRequest(row["original"]["id"])}}>{row["original"]["name"]}</a>
        : cell.render('Cell') 
    )
  }

  const persistStatus = (id, status) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    axios.put(`${appConstants.MONITORING_EVENT_URL}${id}`, {"flash_report_status": status, "date_submitted": moment()} )
    .then(res => {
      refreshData()
    })
    .catch(error => {
      console.log(error)
    })
  }

  const onFlashDragStart = start => {
    onDragStart(start, flashReportingBoard, setFlashReportingBoard)
  }

  const onFlashDragEnd = result => {
    onDragEnd(result, flashReportingBoard, setFlashReportingBoard)
  }

  const onDragStart = (start, board, boardFunction) => {
    const homeIndex = board.columnOrder.indexOf(start.source.droppableId);
  
    const draggingState = {
      ...board,
      homeIndex: homeIndex
    }
    boardFunction(draggingState)
  }

  const onDragEnd = (result, board, boardFunction) => {
    const droppedState = {
      ...board,
      homeIndex: null
    }
    boardFunction(droppedState)

    const { destination, source, draggableId } = result;

    // Moving aborted
    if(!destination) {
      return;
    }

    // Moving to original spot
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = board.columns[source.droppableId]
    const finish = board.columns[destination.droppableId]

    // Moving within same column 
    if(start === finish) {
      const newTaskIds = Array.from(start.taskIds)
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };
    
      const newState = {
        ...board,
        columns: {
          ...board.columns,
          [newColumn.id]: newColumn,
        },
      };
    
      setBoard(newState)
      return
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...board,
      columns: {
        ...board.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    boardFunction(newState)
    persistStatus(draggableId, destination.droppableId)
  }

  // modal/form functions
  const editRequest = (taskId) => {
    setSelectedRequestId(taskId)
    setViewModal(true)
  }

  const newRequest = () => {
    setViewModal(true)
    setSelectedRequestId('')
  }

  const closeModal = () => {
    setViewModal(false)
  }

  const renderMonitoringRequestModalContent = () => {
    return (
      <FlashReportForm col={6}
        allowed={props.allowed}
        id={selectedRequestId}
        tabKey={props.tabKey}
        authenticityToken={props.authenticityToken}
        refreshData={refreshData}
        closeModal={closeModal} />
    )
  }

  const renderRequestModal = () => {
    return (
      <Modal
        show={viewModal}
        onHide={closeModal}
        keyboard={true}
        fullscreen={true}>
        <Modal.Header closeButton>
        <Modal.Title>{`${I18n.t('activerecord.attributes.monitoring_request.monitoring_event')} ${selectedRequestId} ${I18n.t('activerecord.attributes.monitoring_request.flash_report')}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderMonitoringRequestModalContent()}
        </Modal.Body>
      </Modal>
    )
  }

  const renderFiltersButton = () => {
    return (
      <FiltersButton 
        onClick={() => setShowFilters(!showFilters)} 
        aria-controls="collapse-filters" 
        aria-expanded={showFilters}>
          <h4>{showFilters ? <BsBoxArrowUp /> : <BsBoxArrowDown />}</h4>
      </FiltersButton>
    ) 
  }

  const renderFlashReportingBoard = () => {
    return  (
      <Board 
        onDragStart={onFlashDragStart}
        onDragEnd={onFlashDragEnd}
        dropDisabled={dropDisabled}
        taskContent={taskContent}
        initialData={flashReportingBoard}
        isFlashBoard={true}
      />
    )
  }

  const renderList = () => {
    return (selectedTab == "list") ?
      (
        <SortableTable
          data={filteredData}
          columns={tableColumns}
          useRenderFunction={true}
          cellContent={requestModalLink}
        />
      ) : ""
  }

  return (
    <>
      {renderRequestModal()}
      <div>
        <Collapse in={showFilters}>
          <div id="collapse-filters">
            <MonitoringRequestFilters handleFilterChange={handleFilterChange} handleSearch={handleSearch} includedFilters={filters} />
          </div>
        </Collapse>
        {renderFiltersButton()}
      </div>
      <div>
        {renderFlashReportingBoard()}
      </div>
    </>
  )
}

