import React  from 'react'
import { useTable, useSortBy } from 'react-table'
import BTable from 'react-bootstrap/Table';
import {FiArrowUpCircle, FiArrowDownCircle} from "react-icons/fi";
 
export default function SortableTable(props) {
  const data = props.data
  const columns = props.columns
  const link_path = props.link_path

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
      { 
        columns,
        data
      },
      useSortBy
    )

  return (
    <div className="table-responsive table-scroll" style={{position: 'relative', maxHeight: '700px'}}>
      <BTable striped borderless hover size="sm" sticky-top {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th className="align-baseline" {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')} &nbsp;
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <FiArrowDownCircle />
                        : <FiArrowUpCircle />
                        : ''
                    }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      {
                        props.useRenderFunction
                        ? props.cellContent(cell, row)
                        : cell.render('Cell') 
                      }
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </BTable>
    </div>
  )
}