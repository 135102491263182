import React, { useState }  from 'react'
import axios from 'axios'
import styled from 'styled-components'
import I18n from 'i18n-js/locales.js'
import { Button, Tab, Tabs, Modal } from 'react-bootstrap'
import { appConstants } from 'utils/constants.js'
import SortableTable from './tables/SortableTable.js'
import LessonEmailForm from './forms/LessonEmailForm.js'

const SendEmailButton = styled(Button)`
  right: 1.5em;
  position: absolute;
  z-index: 0;
  `;

export default function CompletedLessonEmails(props) {
  const [initialData, setInitialData] = useState(props.initialData.data || [])
  const [viewModal, setViewModal] = useState(false)
  const [selectedTab, setSelectedTab] = useState('unsent')

  const refreshData = () => {
    axios.get(`${appConstants.COMPLETED_LESSONS_URL}.json`)
    .then(res => {
      const lessons = res.data.data;
      setInitialData(lessons)
    })
    .catch(error => {
      console.log(error);
    })
  }

  const renderEmailModalContent = () => {
    return (
      <LessonEmailForm col={6}
        lessons={initialData.unsent}
        authenticityToken={props.authenticityToken}
        refreshData={refreshData}
        closeModal={()=>setViewModal(false)} />
    )
  }

  const renderEmailModal = () => {
    return (
      <Modal
        show={viewModal}
        onHide={()=>setViewModal(false)}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('headers.completed_lesson_emails.send_bha_notifications')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderEmailModalContent()}
        </Modal.Body>
      </Modal>
    )
  }

  const renderList = (data, columns) => {
    return (
      <SortableTable
        data={data}
        columns={columns}
        useRenderFunction={false}
      />
    )
  }

  return (
    <>
      {renderEmailModal()}
      <div>
        <SendEmailButton variant="outline-primary" onClick={()=>setViewModal(true)}>{I18n.t('headers.completed_lesson_emails.send')}</SendEmailButton>
        <Tabs activeKey={selectedTab} id="visitTabs" onSelect={(selectedKey) => setSelectedTab(selectedKey)}>
          <Tab eventKey="unsent" title={I18n.t("headers.completed_visit_emails.unsent")}>
            <br/>
            {renderList(initialData.unsent, props.initialData.unsent_columns)}
          </Tab>
          <Tab eventKey="sent" title={I18n.t("headers.completed_lesson_emails.sent")}>
            <br/>
            {renderList(initialData.sent, props.initialData.sent_columns)}
          </Tab>
        </Tabs>
      </div>
    </>
  )
}

