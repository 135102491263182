import React, { useState, useEffect } from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { Form, Row, Col, Button, Alert, InputGroup, FormControl } from 'react-bootstrap'
import SelectMultiple from '../selects/SelectMultiple.js'
import { appConstants } from 'utils/constants.js'

export default function EmailForm(props) {
  const [visits, setVisits] = useState(props.visits || [])
  const [chosenVisits, setChosenVisits] = useState(props.visits || [])
  const [recipients, setRecipients] = useState([])
  const [alertMessage, setAlertMessage] = useState("")

  useEffect(() => {
    const loadRecipients = async () => {
      const response = await axios.get(appConstants.COMPLETED_VISITS_DIGEST_USERS_URL)
      setRecipients(response.data.map(r => r.id) || [])
    }
    loadRecipients()
  }, [])

  const handleSend = async () => {
    if (recipients.length < 1) {
      setAlertMessage(I18n.t('errors.email_missing'))
      return false
    }

    if (chosenVisits.length < 1) {
      setAlertMessage(I18n.t('errors.visit_missing'))
      return false
    }

    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;

    const postBody = {
      visits: chosenVisits,
      users: recipients
    }

    axios.post(`${appConstants.SEND_COMPLETED_VISITS_URL}`, postBody)
    .then(res => {
      setAlertMessage(I18n.t("notices.email_sent"))
      let remaining = visits.filter(v => !chosenVisits.includes(v))
      setVisits(remaining);
      props.refreshData();
    })
    .catch(err => {
      setAlertMessage(I18n.t("notices.email_failed"))
    })
  }

  const handleAlertMessage = () => {
    if (alertMessage){
      return (
        <Alert variant={'info'} onClose={() => setAlertMessage(null)} dismissible>
          {alertMessage}
        </Alert>
      )
    } else {
      return ""
    }
  };

  const handleVisitChoice = (visit) => {
    let choices = Object.assign([], chosenVisits)
    if (choices.indexOf(visit) == -1) {
      choices.push(visit);
    } else {
      choices.splice(choices.indexOf(visit), 1);
    };

    if (choices !== chosenVisits) {
      setChosenVisits(choices);
    };
  }

  const renderVisit = (visit, index) => {
    return (
      <InputGroup className="mb-1" key={`${visit.id}_input`}>
        <InputGroup.Checkbox
          id={`visit_${visit.id}`}
          name="send_email"
          checked={chosenVisits.indexOf(visit) !== -1}
          value={true}
          onChange={() => handleVisitChoice(visit)}
          className="form-check-input mt-0" />
        <FormControl className="completed-visit-list-id" placeholder={visit.id} disabled={true} />
        <FormControl placeholder={visit.name} disabled={true} />
      </InputGroup>
    )
  }

  return (
    <Form>
      <Row>
        <Col>
          {handleAlertMessage()}
        </Col>
      </Row>

      <h5>{I18n.t('headers.completed_visit_emails.visits_to_include')}</h5>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="emailForm.visits">
          {visits.map((v, i) => renderVisit(v,i))}
        </Form.Group>
      </Row>

      <h5>{I18n.t('headers.completed_visit_emails.recipients')}</h5>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="emailForm.recipients">
          <SelectMultiple
            selected={recipients}
            onChange={(e) => setRecipients(e ? e.map(i => i.value) : [])}
            url={appConstants.COMPLETED_VISITS_DIGEST_USERS_URL}
            placeholder={I18n.t('selects.multiple_optional')} />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" controlId="emailForm.buttons">
        <Button variant="outline-primary" onClick={handleSend}>{I18n.t("navigation.send")}</Button> &nbsp;
        <Button variant="outline-secondary" onClick={props.closeModal}>{I18n.t("navigation.cancel")}</Button>
      </Form.Group>
    </Form>
  );
};